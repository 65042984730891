import { Button, Checkbox, Chip, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import { createApiClient } from '../api/ApiClient';
import { Context } from '../context/ContextStore';
import AuthorityDto from '../model/DTOs/AuthorityDto';
import ClientDto, { GrantTypes } from '../model/DTOs/ClientDto';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useQuery } from 'react-query';
import ResourceDto from '../model/DTOs/ResourceDto';

const useStyles = makeStyles(() =>
  createStyles({
    tokenValidityContainer: {
      marginTop: '0.5em',
    },
    tokenValidtyContainerLeft: {
      marginRight: '0.5em',
      flex: 1,
    },
    tokenValidtyContainerRight: {
      marginLeft: '0.5em',
      flex: 1,
    },
    tokenValidityTextField: {
      width: '100%',
    },
    chip: {
      margin: '0.5em',
    },
    divider: {
      margin: '1em',
    },
  })
);

export interface IAddEditClientDialogProps {
  existingClient?: ClientDto;
  open: boolean;
  onClose: () => void;
}

const AddEditClientDialog: React.FunctionComponent<IAddEditClientDialogProps> = (props) => {
  const classes = useStyles();

  const [context, dispatchContext] = useContext(Context);

  const { existingClient, open, onClose } = props;

  // Client
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [authorizedGrantTypes, setAuthorizedGrantTypes] = useState<GrantTypes[]>([GrantTypes.RefreshToken]);
  const [accessTokenValiditySeconds, setAccessTokenValiditySeconds] = useState(1800);
  const [refreshTokenValiditySeconds, setRefreshTokenValiditySeconds] = useState(604800);
  const [authorities, setAuthorities] = useState<AuthorityDto[]>([]);
  const [resources, setResources] = useState<ResourceDto[]>([]);

  // User experience
  const [clientIdTouched, setClientIdTouched] = useState(false);
  const [clientSecretTouched, setClientSecretTouched] = useState(false);
  const [authorizedGrantTypesTouched, setAuthorizedGrantTypesTouched] = useState(false);
  const [accessTokenValiditySecondsTouched, setAccessTokenValiditySecondsTouched] = useState(false);
  const [refreshTokenValiditySecondsTouched, setRefreshTokenValiditySecondsTouched] = useState(false);

  // Errors
  const clientIdError = clientIdTouched && (clientId !== undefined && clientId.length === 0);
  const clientSecretError = clientSecretTouched && (clientSecret !== undefined && clientSecret.length === 0);
  const authorizedGrantTypesError = authorizedGrantTypesTouched && (authorizedGrantTypes !== undefined && authorizedGrantTypes.filter((grantType) => grantType !== GrantTypes.RefreshToken).length === 0);
  const accessTokenValiditySecondsError = accessTokenValiditySecondsTouched && (accessTokenValiditySeconds !== undefined && accessTokenValiditySeconds <= 0);
  const refreshTokenValiditySecondsError = refreshTokenValiditySecondsTouched && (refreshTokenValiditySeconds !== undefined && refreshTokenValiditySeconds <= 0);

  // Fetch the available resources
  const availableResources = useQuery(['resources'], () =>
    createApiClient(context, dispatchContext).get(`${context.config?.MAS_URL}/api/v1/resources`).json<ResourceDto[]>()
  );

  // Fetch the available authorities
  const availableAuthorities = useQuery(['authorities'], () =>
    createApiClient(context, dispatchContext).get(`${context.config?.MAS_URL}/api/v1/authorities`).json<AuthorityDto[]>()
  );

  useEffect(() => {
    setClientId("");
    setClientSecret("");
    setAuthorizedGrantTypes([GrantTypes.RefreshToken]);
    setAccessTokenValiditySeconds(1800);
    setRefreshTokenValiditySeconds(604800);
    setAuthorities([]);
    setResources([]);

    // Reset user experience when the dialog is closed
    setClientIdTouched(false);
    setClientSecretTouched(false);
    setAuthorizedGrantTypesTouched(false);
    setAccessTokenValiditySecondsTouched(false);
    setRefreshTokenValiditySecondsTouched(false);

    if (existingClient) {
      setClientId(existingClient.clientId ?? "");
      setAuthorizedGrantTypes(existingClient.authorizedGrantTypes ?? []);
      setAccessTokenValiditySeconds(existingClient.accessTokenValiditySeconds ?? 1800);
      setRefreshTokenValiditySeconds(existingClient.refreshTokenValiditySeconds ?? 604800);
      setAuthorities(existingClient.authorities ?? []);
      setResources(existingClient.resources ?? []);
    }
  }, [existingClient, open]);

  const handleClose = () => {
    onClose();
  };

  const handleGrantTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthorizedGrantTypesTouched(true);

    let index = authorizedGrantTypes.indexOf(event.target.name as GrantTypes);
    let newGrantTypes = [...authorizedGrantTypes];

    if (event.target.checked) {
      if (index === -1) {
        newGrantTypes.push(event.target.name as GrantTypes);
      } else {
        newGrantTypes.splice(index, 1);
      }
    } else {
      if (index !== -1) {
        newGrantTypes.splice(index, 1);
      }
    }

    setAuthorizedGrantTypes(newGrantTypes);
  };

  const handleAddOrEdit = () => {
    let tempClient: ClientDto = {
      clientId: clientId,
      authorizedGrantTypes: authorizedGrantTypes,
      accessTokenValiditySeconds: accessTokenValiditySeconds,
      refreshTokenValiditySeconds: refreshTokenValiditySeconds,
      authorities: authorities
    }

    if (clientSecretTouched && clientSecret.length !== 0) {
      tempClient.clientSecret = clientSecret;
    }

    if (!existingClient) {
      createApiClient(context, dispatchContext)
        .post(`${context.config?.MAS_URL}/api/v1/clients`, {
          json: tempClient,
        })
        .then(handleClose)
        .catch(() => { });
    } else {
      createApiClient(context, dispatchContext)
        .patch(`${context.config?.MAS_URL}/api/v1/clients/${existingClient.uuid}`, {
          json: tempClient,
          searchParams: { replace: true }
        })
        .then(handleClose)
        .catch(() => { });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{existingClient ? 'Edit' : 'Add'} Client</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Client Information</Typography>
        <TextField
          margin="dense"
          id="id"
          label="Client ID"
          type="id"
          fullWidth
          value={clientId}
          onChange={(event) => {
            setClientIdTouched(true);
            setClientId(event.target.value);
          }}
          error={clientIdError}
          required
        />
        <TextField
          margin="dense"
          id="secret"
          label="Client Secret"
          type="password"
          autoComplete="new-password"
          fullWidth
          value={clientSecret}
          onChange={(event) => {
            setClientSecretTouched(true);
            setClientSecret(event.target.value);
          }}
          error={clientSecretError}
          required
        />
        <div className={classes.divider} />
        <Typography variant="h6">Token Settings</Typography>
        <div className={classes.divider} />
        <FormControl required error={authorizedGrantTypesError}>
          <FormLabel component="legend">Authorized Grant Type</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={authorizedGrantTypes.indexOf(GrantTypes.Password) !== -1}
                  onChange={handleGrantTypeChange}
                  name={GrantTypes.Password}
                />
              }
              label="Password"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={authorizedGrantTypes.indexOf(GrantTypes.ClientCredentials) !== -1}
                  onChange={handleGrantTypeChange}
                  name={GrantTypes.ClientCredentials}
                />
              }
              label="Client Credentials"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={authorizedGrantTypes.indexOf(GrantTypes.AuthorizationCode) !== -1}
                  onChange={handleGrantTypeChange}
                  name={GrantTypes.AuthorizationCode}
                  disabled
                />
              }
              label="Authorization Code"
            />
          </FormGroup>
          {authorizedGrantTypesError && <FormHelperText>Select at least one</FormHelperText>}
        </FormControl>
        <div className={classes.divider} />
        <FormControl>
          <FormLabel component="legend">Additional Settings</FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={authorizedGrantTypes.indexOf(GrantTypes.RefreshToken) !== -1}
                onChange={handleGrantTypeChange}
                name={GrantTypes.RefreshToken}
              />
            }
            label="Allow Refresh Token"
          />
        </FormControl>
        <Grid container className={classes.tokenValidityContainer} alignItems="center" justify="center">
          <Grid item className={classes.tokenValidtyContainerLeft}>
            <TextField
              id="accessTokenValiditySeconds"
              className={classes.tokenValidityTextField}
              color="primary"
              label="Access Token Validity Seconds"
              type="number"
              value={accessTokenValiditySeconds}
              onChange={(event) => {
                setAccessTokenValiditySecondsTouched(true);
                setAccessTokenValiditySeconds(Number(event.target.value));
              }}
              error={accessTokenValiditySecondsError}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item className={classes.tokenValidtyContainerRight}>
            <TextField
              id="refreshTokenValiditySeconds"
              className={classes.tokenValidityTextField}
              color="primary"
              label="Refresh Token Validity Seconds"
              type="number"
              value={refreshTokenValiditySeconds}
              onChange={(event) => {
                setRefreshTokenValiditySecondsTouched(true);
                setRefreshTokenValiditySeconds(Number(event.target.value));
              }}
              disabled={authorizedGrantTypes === undefined || authorizedGrantTypes.indexOf(GrantTypes.RefreshToken) === -1}
              error={refreshTokenValiditySecondsError}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
        <div className={classes.divider} />
        <Typography variant="h6">Access Control</Typography>
        <div className={classes.divider} />
        <Autocomplete
          fullWidth
          multiple
          id="combo-box-authorities"
          options={availableAuthorities.data?.map((a) => a.name).sort() ?? []}
          value={authorities.map((a) => a.name).sort()}
          renderInput={(params) => <TextField {...params} label="Client Authorities" variant="outlined" size="small" placeholder="Authorities" />}
          renderOption={(option, { inputValue }) => {
            const matches = match(option, inputValue);
            const parts = parse(option, matches);

            return (
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip className={classes.chip} color="primary" label={option} size="small" {...getTagProps({ index })} />)
          }
          onChange={(_, value) => setAuthorities((old) => { return { ...old, value } })}
        />
        <div className={classes.divider} />
        <Autocomplete
          fullWidth
          multiple
          id="combo-box-resources"
          options={availableResources.data?.map((a) => a.name).sort() ?? []}
          value={resources.map((a) => a.name).sort()}
          renderInput={(params) => <TextField {...params} label="Assigned Resources" variant="outlined" size="small" placeholder="Resources" />}
          renderOption={(option, { inputValue }) => {
            const matches = match(option, inputValue);
            const parts = parse(option, matches);

            return (
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip className={classes.chip} color="primary" label={option} size="small" {...getTagProps({ index })} />)
          }
          onChange={(_, value) => setResources((old) => { return { ...old, value } })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleAddOrEdit}>
          {existingClient ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditClientDialog;

import { Button, Checkbox, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { createApiClient } from '../api/ApiClient';
import { Context } from '../context/ContextStore';
import PrivilegeDto from '../model/DTOs/PrivilegeDto';
import UserDto, { UserRole } from '../model/DTOs/UserDto';
import Utils from '../utils/Utils';
import PrivilegeTransferList from '../components/PrivilegeTransferList';
import { useQuery } from 'react-query';

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      width: '100%',
    },
    divider: {
      margin: '1em',
    },
    roleRadioGroup: {
      display: 'flex',
      flexDirection: 'row',
    },
    privilegeList: {
      marginTop: '1em',
    },
  })
);

export interface IAddEditUserDialogProps {
  existingUser?: UserDto;
  open: boolean;
  onClose: () => void;
}

const AddEditUserDialog: React.FunctionComponent<IAddEditUserDialogProps> = (props) => {
  const classes = useStyles();

  const [context, dispatchContext] = useContext(Context);

  const { existingUser, open, onClose } = props;

  // User information
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState<UserRole[]>([UserRole.User]);
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);

  // User experience
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [rolesTouched, setRolesTouched] = useState(false);

  // Errors
  const usernameError = usernameTouched && username?.length === 0;
  const passwordError = passwordTouched && confirmPasswordTouched && (password?.length === 0 || password !== confirmPassword);
  const confirmPasswordError = confirmPasswordTouched && passwordTouched && (confirmPassword?.length === 0 || password !== confirmPassword);
  const emailError = emailTouched && !email?.match(Utils.EMAIL_REGEX);
  const roleError = rolesTouched && roles?.length === 0;

  // Clears the inputs when opening the dialog
  useEffect(() => {
    setUsername("");
    setPassword("");
    setConfirmPassword("");
    setFirstname("");
    setLastname("");
    setEmail("");
    setRoles([UserRole.User])
    setPrivileges([]);

    setUsernameTouched(false);
    setPasswordTouched(false);
    setConfirmPasswordTouched(false);
    setEmailTouched(false);
    setRolesTouched(false);

    if (existingUser) {
      setUsername(existingUser.username ?? "");
      setFirstname(existingUser.firstname ?? "");
      setLastname(existingUser.lastname ?? "")
      setEmail(existingUser.email ?? "");
      setRoles(existingUser.roles ?? [UserRole.User]);
      setPrivileges(existingUser.privileges ?? []);
    }
  }, [existingUser, open]);

  const availablePrivileges = useQuery(['privileges'], () =>
    createApiClient(context, dispatchContext).get(`${context.config?.MAS_URL}/api/v1/privileges`, { searchParams: { size: 2000 } }).json<PrivilegeDto[]>()
  );

  const handleClose = () => {
    onClose();
  };

  const handleRoleToggle = (role: UserRole) => {
    setRolesTouched(true);

    const index = roles.indexOf(role);
    const newRoles = [...roles];
    if (index === -1) {
      newRoles.push(role);
    } else {
      newRoles.splice(index, 1);
    }

    setRoles(newRoles);
  };

  const handleAddOrEdit = () => {
    let tempUser: UserDto = {
      username: username,
      firstname: firstname,
      lastname: lastname,
      email: email,
      roles: roles,
      privileges: privileges
    };

    if (passwordTouched && password.length !== 0) {
      tempUser.password = password;
    }

    if (!existingUser) {
      createApiClient(context, dispatchContext)
        .post(`${context.config?.MAS_URL}/api/v1/users`, {
          json: tempUser,
        })
        .then(handleClose)
        .catch(() => { });
    } else {
      createApiClient(context, dispatchContext)
        .patch(`${context.config?.MAS_URL}/api/v1/users/${existingUser.uuid}`, {
          json: tempUser,
          searchParams: { replace: true }
        })
        .then(handleClose)
        .catch(() => { });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{existingUser ? 'Edit' : 'Add'} User</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Personal Information</Typography>
        <TextField
          margin="dense"
          id="name"
          label="Username"
          type="name"
          fullWidth
          value={username}
          onChange={(event) => {
            setUsernameTouched(true);
            setUsername(event.target.value);
          }}
          error={usernameError}
          required
        />
        <TextField
          margin="dense"
          id="password"
          label="New Password"
          type="password"
          autoComplete="new-password"
          fullWidth
          value={password}
          onChange={(event) => {
            setPasswordTouched(true);
            setPassword(event.target.value);
          }}
          error={passwordError}
          required
        />
        <TextField
          margin="dense"
          id="confirmPassword"
          label="Confirm Password"
          type="password"
          autoComplete="new-password"
          fullWidth
          value={confirmPassword}
          onChange={(event) => {
            setConfirmPasswordTouched(true);
            setConfirmPassword(event.target.value);
          }}
          error={confirmPasswordError}
          required
        />
        <TextField
          margin="dense"
          id="firstname"
          label="Firstname"
          type="firstname"
          fullWidth
          value={firstname}
          onChange={(event) => {
            setFirstname(event.target.value);
          }}
        />
        <TextField
          margin="dense"
          id="lastname"
          label="Lastname"
          type="lastname"
          fullWidth
          value={lastname}
          onChange={(event) => {
            setLastname(event.target.value);
          }}
        />
        <TextField
          margin="dense"
          id="email"
          label="E-Mail"
          type="email"
          fullWidth
          value={email}
          onChange={(event) => {
            setEmailTouched(true);
            setEmail(event.target.value);
          }}
          error={emailError}
          required
        />
        <div className={classes.divider} />
        <Typography variant="h6">Access Control</Typography>
        <div className={classes.divider} />
        <FormControl className={classes.form} component="fieldset" required error={roleError}>
          <FormLabel component="legend">Role</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={roles.indexOf(UserRole.User) !== -1}
                  onChange={() => handleRoleToggle(UserRole.User)}
                />
              }
              label="User"
            />
            <FormControlLabel
              control={
                <Checkbox color="primary"
                  checked={roles.indexOf(UserRole.Supervisor) !== -1}
                  onChange={() => handleRoleToggle(UserRole.Supervisor)}
                />
              }
              label="Supervisor"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={roles.indexOf(UserRole.Administrator) !== -1}
                  onChange={() => handleRoleToggle(UserRole.Administrator)}
                />
              }
              label="Administrator"
            />
          </FormGroup>
          <FormHelperText>Please select at least one</FormHelperText>
        </FormControl>
        <div className={classes.divider} />
        <FormControl className={classes.form} component="fieldset" required>
          <FormLabel component="legend">Privileges</FormLabel>
          <div className={classes.privilegeList}>
            <PrivilegeTransferList privileges={availablePrivileges.data ?? []} assignedPrivileges={privileges} onPrivilegeUpdate={(newPrivileges) => setPrivileges(newPrivileges)} />
          </div>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleAddOrEdit}>
          {existingUser ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditUserDialog;

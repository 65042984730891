import { Button, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { createStyles } from '@material-ui/styles';
import React, { useState, useContext } from 'react';
import { createApiClient } from '../api/ApiClient';
import { Context } from '../context/ContextStore';
import ClientDto from '../model/DTOs/ClientDto';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Loading from './Loading';
import AddEditClientDialog from '../dialogs/AddEditClientDialog';
import ManageAuthoritiesDialog from '../dialogs/ManageAuthoritiesDialog';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import PagedResult from '../model/PagedResult';
import ManageResourcesDialog from '../dialogs/ManageResourcesDialog';
import Utils from "../utils/Utils";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 'auto',
      margin: '2em',
    },
    subHeader: {
      paddingLeft: '1em',
      paddingRight: '1em',
      paddingBottom: '1em',
    },
    title: {
      flexGrow: 1,
    },
    passwordField: {
      fontSize: 'x-large',
      margin: '0px',
      padding: '0px',
    },
    button: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
  })
);

const ClientsTable: React.FunctionComponent = () => {
  const classes = useStyles();
  const [context, dispatchContext] = useContext(Context);

  const [allClientsCount, setAllClientsCount] = useState(0);
  const [clients, setClients] = useState<ClientDto[]>([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedClient, setSelectedClient] = useState<ClientDto>();
  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);
  const [manageAuthoritiesDialogOpen, setManageAuthoritiesDialogOpen] = useState(false);
  const [manageResourcesDialogOpen, setManageResourcesDialogOpen] = useState(false);

  const queryClient = useQueryClient();

  const clientsQuery = useQuery(
    ['clients', { page, rowsPerPage }],
    () =>
      createApiClient(context, dispatchContext)
        .get(`${context.config?.MAS_URL}/api/v1/clients?page=${page}&size=${rowsPerPage}`)
        .json<PagedResult<ClientDto>>(),
    {
      keepPreviousData: true,
      onSuccess: (response) => {
        setAllClientsCount(response.count);
        setClients(response.data);
      },
    }
  );

  const deleteClient = useMutation(
    (clientToDelete: ClientDto) =>
      createApiClient(context, dispatchContext)
        .delete(`${context.config?.MAS_URL}/api/v1/clients/${clientToDelete.uuid}`)
        .json<ClientDto[]>(),
    { onSuccess: () => queryClient.invalidateQueries('clients') }
  );

  function handleChangePage(_: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  return (
    <div className={classes.container}>
      <Grid container className={classes.subHeader} direction="row" alignItems="center">
        <Typography className={classes.title} variant="h4">
          Clients
        </Typography>
        <Grid item>
          <Grid container>
            <Button className={classes.button} color="primary" variant="contained" onClick={() => setManageResourcesDialogOpen(true)}>
              Manage Resources
            </Button>
            <Button className={classes.button} color="primary" variant="contained" onClick={() => setManageAuthoritiesDialogOpen(true)}>
              Manage Authorities
            </Button>
            <Button className={classes.button} color="primary" variant="contained" onClick={() => setAddEditDialogOpen(true)}>
              Add Client
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Client ID</TableCell>
              <TableCell align="left">Resources</TableCell>
              <TableCell align="left">Authorized Grant Types</TableCell>
              <TableCell align="left">Authorities</TableCell>
              <TableCell align="left">Access Token Validity</TableCell>
              <TableCell align="left">Refresh Token Validity</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {clientsQuery.isLoading ? (
              <TableRow key="loading">
                <TableCell colSpan={8}>
                  <Loading />
                </TableCell>
              </TableRow>
            ) : (
              clients.map((client) => (
                <TableRow key={client.uuid}>
                  <TableCell align="left">{client.clientId}</TableCell>
                  <TableCell align="left">{client.resources?.map((r) => r.name).sort(Utils.sortAlphabetically).join(', ')}</TableCell>
                  <TableCell align="left">{client.authorizedGrantTypes?.sort(Utils.sortAlphabetically).join(', ')}</TableCell>
                  <TableCell align="left">{client.authorities?.map((a) => a.name).sort(Utils.sortAlphabetically).join(', ')}</TableCell>
                  <TableCell align="left">{client.accessTokenValiditySeconds}</TableCell>
                  <TableCell align="left">{client.refreshTokenValiditySeconds || 'none'}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedClient(client);
                        setAddEditDialogOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        deleteClient.mutate(client);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                rowsPerPage={rowsPerPage}
                colSpan={8}
                count={allClientsCount}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <AddEditClientDialog
        existingClient={selectedClient}
        open={addEditDialogOpen}
        onClose={() => {
          queryClient.invalidateQueries('clients');
          setSelectedClient(undefined);
          setAddEditDialogOpen(false);
        }}
      />
      <ManageAuthoritiesDialog
        open={manageAuthoritiesDialogOpen}
        onClose={() => {
          queryClient.invalidateQueries('authorities');
          setManageAuthoritiesDialogOpen(false);
        }}
      />
      <ManageResourcesDialog
        open={manageResourcesDialogOpen}
        onClose={() => {
          queryClient.invalidateQueries('resources');
          setManageResourcesDialogOpen(false);
        }}
      />
    </div>
  );
};

export default ClientsTable;

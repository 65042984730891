import React, { useContext, useEffect, useState } from 'react';
import {
  AppBar,
  Button,
  ClickAwayListener,
  createStyles,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import Logo from '../assets/logo.svg';
import { AccountCircle } from '@material-ui/icons';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import UsersTable from '../components/UsersTable';
import ClientsTable from '../components/ClientsTable';
import clsx from 'clsx';
import { Context } from "../context/ContextStore";
import { IActionType } from "../context/ContextInterfaces";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      height: '5em',
    },
    title: {
      flexGrow: 1,
    },
    logo: {
      height: '100%',
      paddingRight: '2em',
    },
    selectedPage: {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '0px',
    },
  })
);

const Dashboard: React.FunctionComponent = () => {
  const classes = useStyles();

  const [, dispatchContext] = useContext(Context);

  const [activePage, setActivePage] = useState('');

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const history = useHistory();

  useEffect(() => {
    setActivePage(history.location.pathname.split('/').pop() || '');
  }, [history.location.pathname]);

  const gotoPage = (subPage: string) => {
    setActivePage(subPage);
    history.push(subPage);
  };

  const handleMenuToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleMenuClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <img src={Logo} alt="mindmotiv_logo" className={classes.logo} />
          <Typography className={classes.title} variant="h6">
            Mindmotiv Authentication Server
          </Typography>
          <Button
            className={clsx(activePage === 'users' ? classes.selectedPage : '')}
            color="secondary"
            onClick={() => {
              gotoPage('/dashboard/users');
            }}
          >
            Users
          </Button>
          <Button
            className={clsx(activePage === 'clients' ? classes.selectedPage : '')}
            color="secondary"
            onClick={() => {
              gotoPage('/dashboard/clients');
            }}
          >
            Clients
          </Button>
          <IconButton color="secondary" ref={anchorRef} onClick={handleMenuToggle}>
            <AccountCircle />
          </IconButton>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleMenuClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <MenuItem
                        onClick={() => {
                          handleMenuToggle();
                          dispatchContext({ type: IActionType.SET_AUTHENTICATED, payload: false });
                          dispatchContext({ type: IActionType.REMOVE_ACCESSTOKEN });
                          dispatchContext({ type: IActionType.REMOVE_REFRESHTOKEN });
                          gotoPage('/login');
                        }}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>
      <Switch>
        <Route path="/dashboard/users" render={() => <UsersTable />} />
        <Route path="/dashboard/clients" render={() => <ClientsTable />} />
        <Redirect to="/dashboard/users" />
      </Switch>
    </>
  );
};

export default Dashboard;

import { Button, createStyles, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import React, { useState, useContext } from 'react';
import { createApiClient } from '../api/ApiClient';
import { Context } from '../context/ContextStore';
import UserDto, { UserRole } from '../model/DTOs/UserDto';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Loading from './Loading';
import AddEditUserDialog from '../dialogs/AddEditUserDialog';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ManagePrivilegesDialog from '../dialogs/ManagePrivilegesDialog';
import PagedResult from '../model/PagedResult';
import Utils from "../utils/Utils";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 'auto',
      margin: '2em',
    },
    subHeader: {
      paddingLeft: '1em',
      paddingRight: '1em',
      paddingBottom: '1em',
    },
    title: {
      flexGrow: 1,
    },
    button: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
  })
);

const UsersTable: React.FunctionComponent = () => {
  const classes = useStyles();
  const [context, dispatchContext] = useContext(Context);

  const [allUsersCount, setAllUsersCount] = useState(0);
  const [users, setUsers] = useState<UserDto[]>([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedUser, setSelectedUser] = useState<UserDto>();
  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);
  const [managePrivilegesDialogOpen, setManagePrivilegesDialogOpen] = useState(false);

  const usersQuery = useQuery(
    ['users', { page, rowsPerPage }],
    () =>
      createApiClient(context, dispatchContext)
        .get(`${context.config?.MAS_URL}/api/v1/users?page=${page}&size=${rowsPerPage}`)
        .json<PagedResult<UserDto>>(),
    {
      keepPreviousData: true,
      onSuccess: (response) => {
        setAllUsersCount(response.count);
        setUsers(response.data);
      },
    }
  );

  const deleteUser = useMutation(
    (userToDelete: UserDto) =>
      createApiClient(context, dispatchContext).delete(`${context.config?.MAS_URL}/api/v1/users/${userToDelete.uuid}`).json<UserDto[]>(),
    { onSuccess: () => queryClient.invalidateQueries('users') }
  );

  const queryClient = useQueryClient();

  function handleChangePage(_: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  }

  return (
    <div className={classes.container}>
      <Grid container className={classes.subHeader} direction="row" alignItems="center">
        <Typography className={classes.title} variant="h4">
          Users
        </Typography>
        <Grid item>
          <Grid container>
            <Button className={classes.button} color="primary" variant="contained" onClick={() => setManagePrivilegesDialogOpen(true)}>
              Manage Privileges
            </Button>
            <Button className={classes.button} color="primary" variant="contained" onClick={() => setAddEditDialogOpen(true)}>
              Add User
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Username</TableCell>
              <TableCell align="left">Roles</TableCell>
              <TableCell align="left">Privileges</TableCell>
              <TableCell align="left">Firstname</TableCell>
              <TableCell align="left">Lastname</TableCell>
              <TableCell align="left">E-Mail</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {usersQuery.isLoading ? (
              <TableRow key="loading">
                <TableCell colSpan={7}>
                  <Loading />
                </TableCell>
              </TableRow>
            ) : (
              users.map((user) => (
                <TableRow key={user.uuid}>
                  <TableCell align="left">{user.username}</TableCell>
                  <TableCell align="left">{user.roles?.map((role) => Object.keys(UserRole).filter((origRole) => UserRole[origRole as keyof typeof UserRole] === role)[0] ?? 'Unknown').join(', ')}</TableCell>
                  <TableCell align="left">{user.privileges?.map((p) => p.name).sort(Utils.sortAlphabetically).join(', ')}</TableCell>
                  <TableCell align="left">{user.firstname}</TableCell>
                  <TableCell align="left">{user.lastname}</TableCell>
                  <TableCell align="left">{user.email}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedUser(user);
                        setAddEditDialogOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        deleteUser.mutate(user);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                rowsPerPage={rowsPerPage}
                colSpan={7}
                count={allUsersCount}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <AddEditUserDialog
        existingUser={selectedUser}
        open={addEditDialogOpen}
        onClose={() => {
          queryClient.invalidateQueries('users');
          setSelectedUser(undefined);
          setAddEditDialogOpen(false);
        }}
      />
      <ManagePrivilegesDialog
        open={managePrivilegesDialogOpen}
        onClose={() => {
          queryClient.invalidateQueries('privileges');
          setManagePrivilegesDialogOpen(false);
        }}
      />
    </div>
  );
};

export default UsersTable;
